h1 {
  text-align: center;
  color: #fff;
}

.card__item {
  background-color: #fff;
  height: min-content;
  margin: 0 1rem;
  border-radius: 10px;
  margin-bottom: 24px;
  padding: 10px 7px 10px 7px;
  /* width: 320px; */
}

.card__header {
  display: inline-block;
  padding: 5px 10px 0px;
}

.card__title {
  color: rgb(54, 2, 102);
  padding-bottom: 5px;
}

.card__title__bar {
  height: 3px;
  color: black;
  background-color: black;
  width: 105%;
}

.card__text {
  color: #252e48;
  font-size: 18px;
  line-height: 24px;
  display: block;
}

.username__box {
  content: attr(data-category);
  position: relative;
  top: 10px;
  right: 8px;
  height: 24px;
  padding: 5px 8px;
  width: min-content;
  max-width: 200px;
  overflow: hidden;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-color: #570351;
  box-sizing: border-box;
}

.card__image {
  max-width: 100%;
}

input,
label {
  display: block;
  padding: 4px;
  width: 100%;
}
h6 {
  color: red;
}
textarea {
  box-sizing: border-box;
  height: 100px;
  padding: 4px;
  width: 100%;
}

.form__submit {
  padding-top: 10px;
}

.cards__item__info {
  padding: 20px 30px 30px;
}

.cards__item__text {
  color: #252e48;
  font-size: 18px;
  line-height: 24px;
}

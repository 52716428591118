.cards {
  padding: 4rem;
  background-image: url("/images/space.jpg");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

h1 {
  text-align: center;
}
